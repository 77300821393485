@import 'colors';
$mobilewidth: 812px;

// Breakpoints taken from: https://getbootstrap.com/docs/4.3/layout/overview/
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

html {-webkit-text-size-adjust: 100%;}
.big-picture .zoomed-in {
  overflow: scroll;
}

html.react-snap {
  overflow-y: hidden;
  .right-buttons {
    visibility: hidden;
  }
}

body {background-color:#F4F4f4 !important; font-family: Arial, Helvetica, sans-serif;}
body.popup {background-color:transparent !important;}

a {text-decoration: none; transition: all 0.5s; color: $blue;
  &:hover {transition: all 0.1s; color: $blue-hover;}
}

.wrapper{
  overflow:hidden;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding-left: 20px;
}

.content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #aaa;
}


.sh_wrapper{
  position: relative;
  width: 100%;
  font-size: 1em;
  background-color: #e8e8e8;
  border-radius: 10px;
  margin: 0.5rem 5px;

  div{
    color: #565656;
    font-size:0.8em !important;
  }

  .link {
    text-decoration: none;
    transition: all 0.5s;
    cursor: pointer;
    color: $blue;
    &:hover {transition: all 0.1s; color: $blue-hover;}
  }
}

.disclaimer {
  margin: -60px 5px 14px;
  color: $navy;
  font-size: 0.8em;
  padding-right: 220px;
  color: $navy-light;
  a {color: #366fa8;}
  h1 {font-size: 2.1rem; line-height: 30px; display: block; margin: 0 0 14px; color: $navy;
    @media (max-width: $lg-screen) {font-size: 1.4rem; line-height: normal;}
  }
  @media (max-width: $lg-screen) {
    padding-right: 0;
  }
}

.summary {
  margin: 0 5px 14px;
  color: $navy;
  font-size: 0.8rem;
}

.big-picture {
  @media (max-width: $lg-screen) {
    .summary,
    .disclaimer {
      display: none;
    }
  }
}

.fullscreen {
  .summary,
  .disclaimer {
    display: none;
  }
}

.header_container {
  z-index: 2;
  height: 70px;

  @media (max-width: $lg-screen) {height: 60px;}

  .header {
    padding: 20px;
    .landscape-logo {
      position: fixed;
      top: 0;
      left: 0;
      padding: 20px;
      width: 215px;
      height: 70px;
      z-index: 2;
      cursor: pointer;
      background: #f4f4f4;
      img {
        width: 127px;
        @media (max-width: 400px) { width: 115px; }
      }
      transform: none; transition: all 0.3s;
      @media (max-width: $lg-screen) {width: 100%; padding-left: 45px;}

    }
    .landscapeapp-logo {display: block; width: 170px; height: 30px; position: absolute; top: 20px; right: 20px; z-index: 3;
      img {width: 100%;}
      @media (max-width: $lg-screen) {position: fixed;}
      @media (max-width: $md-screen) {width: 155px;}
    }
    .info {margin-top: -32px; padding-left: 20px; height: auto;}
  }
}

.zoomed-in .header_container .header .landscape-logo,
.zoomed-in .header_container .header .landscapeapp-logo {
  position: absolute;
}

.sidebar {width: 205px; position: fixed; top: 60px; left: 0; bottom: 0; overflow: hidden; z-index: 6;
  &-scroll {padding: 0 20px 0px 20px; position: absolute; top: 0; right: 0; bottom: 0; left: 0;
    overflow: hidden; overflow-y: auto;
  }
  /* @media (max-height: 799px) {position: absolute; bottom: auto; top: 70px; z-index: 1;} */
  .sidebar-collapse {position: absolute; top: 11px; right: 11px; display: none; z-index: 3;}
  @media (max-width: $lg-screen) {
    position: fixed; bottom: 0; width: 215px;
    transform: translate(-215px, 0); transition: 0.3s; top: 0; z-index: 6; background: #fff;
    padding-top: 12px; overflow: auto;
    .sidebar-collapse {display: block;}
    .filters-opened & {transform: none;}
  }
  fieldset {margin-top: 5px; color: $navy;}
  .content-sidebar {flex: 150px 0 0; padding: 13px 0 0 20px;}
  .filters-action {
    height: 25px; margin-bottom: 5px; color: #366fa8; font-size: 20px; cursor: pointer;
    span {
      font-size: 14px;
      position: relative;
      top: -3px;
    }
    svg {
      font-size: 16px;
    }
    @media (max-width: $lg-screen) {
      margin-top: 15px;
    }
  }
  &-presets {
    margin: 1rem 0; font-size: 0.8rem;
    h4 {margin: 0 0 8px 0;}
    div {margin-bottom: 3px;}
    a {text-decoration: none; display: block; position: relative;
      &:focus {outline: none;}
      &.active {color: #000; font-weight: bold;}
    }
  }
  div {color: $navy;}
  legend {font-size:0.6em !important; color: $navy-light;}

  .title{
    display:flex;
    flex-direction: column;
    font-size:1.3em;
    color: #6d7e90 !important;
    align-items: flex-end;
    padding: 10px 0 0 20px;
    a {color:#949494;}
    p {color: #CCC; font-size: 0.6em; margin: 3px 0;}
  }
  &-note {font-size: 0.8rem; color: #999; background: #e8e8e8; padding: 15px; border-radius: 4px;}
  &-event {margin: 0 -10px; position: relative; display: block;
    img {width: 180px ;}
    }
}

.app {
  position: relative;
  .sidebar-show {
    display: none;
  }
  @media (max-width: $lg-screen) {
    .sidebar-show {
      display: block;
      position: fixed;
      top: 11px;
      left: 0;
      z-index: 3;
    }
  }
}

.zoomed-in .app .sidebar-show {
  position: absolute;
}

.app-overlay {opacity: 0; position: fixed; top: 0; left: 0; bottom: 0; width: 100%; background: #000; margin-left: -100%;
  transition: opacity 0.3s, margin 0s; transition-delay: 0s, 0.3s; z-index: 4;
}
.filters-opened {overflow: hidden;
  .app-overlay {margin: 0; opacity: 0.4; transition-delay: 0s, 0s;}
}

.main {margin-left: 205px; padding: 10px;
  @media (max-width: $lg-screen) {margin-left: 0; position: relative; padding-top: 70px;}
  &-note {background: #e8e8e8; font-size: 0.7rem; color: #999; padding: 10px 15px; border-radius: 3px;}
  &.embed {
    margin-left: 0;
    padding-top: 5px !important;
    min-height: 500px;
  }
}

@media (max-width: $lg-screen) {
  .big-picture .main {
    padding-top: 0;
  }
}

.fullscreen .main {
  margin-left: 0;
  padding-top: 0px;
}


/* grid */
.row {margin-left: -20px; margin-right: -20px; display: flex;}
.col {padding: 0 20px;
  &-25 {width: 25%;}
  &-33 {width: 33.33%;}
  &-50 {width: 50%;}
  &-66 {width: 66.66%;}
  &-75 {width: 75%;}
  &-20 {width: 20%;}
  &-40 {width: 40%;}
  &-60 {width: 60%;}
  &-80 {width: 80%;}
}
.tight-row { display: flex;}
.tight-col {padding-right: 0; padding-left: 20px;}

/* tags */
.tag {display: inline-block; vertical-align: top; white-space: nowrap; border-radius: 4px; font-size: 0;
  margin: 0 5px 5px 0; color: #000; border: 0;
  &:hover {color: #000;}
  &-name {padding: 4px 6px; margin-right: -6px; font-size: 0.6rem; font-weight: bold; display: inline-block; border-radius: 3px 0 0 3px;}
  &-value {padding: 4px 6px; font-size: 0.6rem; display: inline-block;}
  &-blue {background: #a0cfff;
    &:hover {background: #89c3ff;}
  }
  &-grass {background: #a1eaff;
    &:hover {background: #87e4ff;}
  }
  &-purple {background: #d7c1fb;
    &:hover {background: #caa9ff;}
  }
  &-orange {background: #a6f5ed;
    &:hover {background: #7dfff2;}
  }

}

.column-content {display: flex; flex-wrap: wrap; position: relative}

.old-column-content {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.mosaic {
  width: 100%; cursor: pointer; position: relative; border-radius: 2px;
  box-shadow: 1px 1px 3px #d6d6d6; background: #fff; border: 1px solid #fff;

  &-wrap {padding: 5px; width: 200px; display: flex;
    @media (max-width: 1839px) {width: 14.285%;}
    @media (max-width: 1639px) {width: 16.666%;}
    @media (max-width: 1439px) {width: 20%;}
    @media (max-width: 1239px) {width: 25%;}
    @media (max-width: 1039px) {width: 33.333%;}
    @media (max-width: 850px) and (max-height: 750px) {width: 25%;}
    @media (max-width: $mobilewidth) {width: 33.333%;}
    @media (max-width: 550px) {width: 50%;}
    .embed & {
      @media (max-width: 1839px) {width: 12.5%;}
      @media (max-width: 1639px) {width: 14.285%;}
      @media (max-width: 1439px) {width: 16.666%;}
      @media (max-width: 1239px) {width: 20%;}
      @media (max-width: 1039px) {width: 25%;}
      @media (max-width: 850px) and (max-height: 750px) {width: 25%;}
      @media (max-width: $mobilewidth) {width: 33.333%;}
      @media (max-width: 550px) {width: 50%;}
    }
  }

  &.nonoss {background: #eee;}

  .logo_wrapper{padding: 2px; width: 100%; cursor: pointer;
    display: flex; justify-content: center; align-items: center;
    .logo {width: 195px; height: 100px; max-width: 100%; border-radius: 4px 4px 0 0; }
  }


  &-info {
    font-size: 0.6em;
    border-width:1px 0 0 0;
    border-style: solid;
    border-color: #dedede;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    color: $navy-light;
  }
  h5 {
    margin:0 0 5px 0 !important; color: $navy;
    font-size: 1em;
  }
  &-stars {line-height: 15px; text-align: right; white-space: nowrap;
    .material-icons {line-height: 15px; vertical-align: top;}
  }
  &-funding {white-space: nowrap;}
}
.logo-mode {
  .mosaic .mosaic-info * {
    display: none;
  }
  .mosaic .logo_wrapper .logo {
    height: 130px;
  }
  .mosaic .mosaic-info .mosaic-title {
    display: inherit;
    width: 100%;
    font-size: 1.5em;
    text-align: center;
  }
}

.ios {
  body {
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }
  * {-webkit-tap-highlight-color: rgba(0,0,0,0);}
}

.ios {
  .background {position: fixed; background: white; left: 0; top: 0; width: 100%; height: 100%; z-index: 10;
    overflow: hidden;
    mask: url(../images/mask.svg);
    mask-position: 50% 50%;
    mask-size: contain;
    pointer-events: none;

    .shadow {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: #000;
      opacity: 0.3;
      z-index: 11;
    }
  }
  &.landscape .background {mask-image: url(../images/mask-landscape.svg); mask-size: cover;
    @media (min-width: 670px) {mask-image: url(../images/mask-landscape-x.svg); mask-size: cover;}
  }
  &.wide .background {mask: url(../images/mask-wide.svg); mask-size: contain;}
  &.ipad.landscape .background {mask-image: url(../images/mask-ipad-landscape.svg); mask-size: cover;}
  &.ipad.portrait .background {mask: url(../images/mask-ipad-portrait.svg); mask-size: cover;}
}

html.ios.has-selected-item, html.ios.has-selected-item body {
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch
}

.gradient-bg {
background: -moz-linear-gradient(-45deg, rgb(27,68,108) 0%, rgb(92, 107, 156) 100%);
background: -webkit-linear-gradient(-45deg, rgb(27,68,108) 0%, rgb(92, 107, 156) 100%);
background: linear-gradient(135deg, rgb(27,68,108) 0%, rgb(92,107,156) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgb(27,68,108)', endColorstr='rgb(92,107,156)',GradientType=1 );
}

.big-picture-switch * {
  text-transform: none;
}

.big-picture-switch .nav-link {
  color: #6E6E6E;
}

.big-picture-switch-normal {
  display: block !important;
}

.MuiTab-root.nav-link {
  @media (max-width: $xl-screen) {min-width: 120px;}
  @media (max-width: $lg-screen) {min-width: 105px;}
  @media (max-width: $md-screen) {min-width: 90px;}
  @media (max-width: $sm-screen) {padding: 6px; min-width: 80px; }
}

.landscape-wrapper {
  position: relative;
  background: #1b446c;
  width: 100%;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 10px;
}

.zoomed-in .landscape-wrapper {
  display: inline-block;
  width: auto;
  overflow: visible;
}

.cards-section {
  position: relative;
}

.zoom-buttons .zoom-reset {
  min-width: 40px;
}

.tweet-button {
  width: 105px;

  .tweet-count-wrapper {
    display: inline-block;
    position: relative;
    width: auto;
    height: auto;
    background-color: #f1faff;
    border: 1px solid #b4dbee;
    border-radius: 2px;
    margin-left: 10px;
    vertical-align: top;
  }

  .tweet-count-wrapper:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -9px;
    right: auto;
    top: calc(50% - 4px);
    border: 4px solid;
    border-color: transparent #b4dbee transparent transparent;
  }

  .tweet-count-wrapper:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -8px;
    right: auto;
    top: calc(50% - 4px);
    border: 4px solid;
    border-color: transparent #f1faff transparent transparent;
  }

  .tweet-count-wrapper .tweet-count{
    padding: 2px 4px;
    text-align: left;
    font-size: 10px;
    margin: 0
  }

  a {
    display: inline-block;
    border-radius: 2px;
    width: 60px;
    height: 19px;
    background: rgb(25,114,230);
    color: white;
    svg {
      margin-top: 2px;
      margin-left: 5px;
      width: 16px;
      height: 16px;
      fill: white;
    }
    span {
      position: relative;
      margin-left: 2px;
      top: -4px;
      font-size: 10px !important;
      font-weight: bold;
    }
  }
}

.right-buttons {
  position: absolute;
  top: 10px;
  width: auto;
  right: 0;

  .zoom-buttons,
  .fullscreen-button,
  .tweet-button {
    float: right;
  }

  .zoom-buttons button,
  .fullscreen-button button{
    padding: 4px;
  }

  .fullscreen-button {
    margin-left: 2px;
  }

  .tweet-button {
    margin-top: 6px;
  }
}

html:not(.big-picture) .right-buttons {
  .zoom-buttons,
  .fullscreen-button {
    display: none;
  }
}

@media (max-width: $md-screen) {
  .big-picture .main {
    padding-bottom: 40px;
  }

  .big-picture .right-buttons {
    top: initial;
    bottom: -35px;
    width: 100%;
  }

  .zoomed-in .right-buttons > div {
    float: left;
    margin-left: 0;
    margin-right: 2px;
  }

  .zoomed-in .right-buttons .tweet-button {
    margin-left: 7px;
  }
}

@media (max-width: $sm-screen) {
  html:not(.big-picture) .right-buttons {
    position: fixed;
    top: initial;
    bottom: 0;
    width: 100%;
    background-color:#F4F4f4;
    padding: 5px 10px;
    z-index: 10;
  }
}

.MuiMenuItem-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: 26px !important;
}

