@import 'colors';

$mobilewidth: 1000px;

.modal {
  perspective: 2000px;
  overflow: auto;
  display: block;
  &-prev, &-next {position: absolute; top: 50%; margin-top: -20px; font-size: 2.5rem; color: #fff; cursor: pointer;
    opacity: 0.6;
    &:hover {opacity: 1;}
    &[disabled]  {cursor: default; opacity: 0.1;
      &:hover {opacity: 0.1;}
    }
  }
  &-prev {left: -60px;}
  &-next {right: -60px;}
  @media (max-width: 1000px) {
    &-prev {left: -45px;}
    &-next {right: -45px;}
  }
  @media (max-width: 750px) {
    &-prev, &-next {width: 40px; height: 100px; line-height: 100px; margin-top: -50px;
      font-size: 2.5rem; text-align: center; opacity: 1;
      svg {display: none;}
      &:before {content: ""; width: 0; height: 0; border: transparent 20px solid; position: absolute;
        top: 0; bottom: 0; margin: auto;
      }
    }
    &-prev {left: -40px;
      &:before {border-right-color: #fff; border-left-width: 0; right: 5px;}
    }
    &-next {right: -40px;
      &:before {border-left-color: #fff; border-right-width: 0; left: 5px;}
    }
    .row {display: block;}
    .col {width: 100% !important;}
  }
  .modal-body {padding: 40px; overflow: visible; max-height: 640px; width: 90%; max-width: 940px;
    border-radius: 8px; margin: 50px; position: absolute; top: 20px; left: 0; bottom: 20px; right: 0; margin: auto;
  }
  &-close {cursor: pointer; position: absolute; top: 0; right: 0; font-size: 30px; opacity: 0.4; color: #000;
    z-index: 3; width: 60px; line-height: 60px; text-align: center;
    &:hover {opacity: 1;}
  }
}
.modal .modal-content.scroll-all-content {
  padding: 50px 20px 20px; width: auto; height: auto;
  position: absolute; top: 30px; left: 40px; bottom: 30px; right: 40px; margin: 0; max-height: none;
  &.modal-content {
    overflow: auto; -webkit-overflow-scrolling: touch; position: absolute; top: 0; left: 0; bottom: 0; right: 0; padding: 20px;
  }
  .product-logo {
    margin: 0 auto 1rem; position: relative; width: auto; max-width: 250px; height: 180px; top: 0; left: 0;
  }
  .product-tags {
    position: static;
    margin-left: calc(50% - 125px);
  }
  .product-scroll {
    margin: 0; padding: 0; height: auto; overflow: visible; position: static;
  }
}


.embed .modal .modal-body {
  max-height: 480px;
}

.product {
  &-logo {margin-bottom: 2.5rem; box-shadow: 0 1px 4px rgba(0,0,0,0.2); border-radius: 4px; padding: 16px;
    border: #fff 4px solid; height: 172px;
    position: absolute; top: 40px; left: 40px; width: 30%;
    &-img {cursor: pointer; margin: auto; display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; bottom: 0; right: 0; }
    .nonoss & {background: #eee; border-color: #eee;}
  }
  &-tags {
    position: absolute; left: 40px; top: 230px; margin-bottom: 1rem;
  }
  &-main {font-size: 0.8rem; line-height: 130%; color: $navy;}
  &-scroll {
    position: absolute; top: 40px; left: calc(40% - 20px); bottom: 40px; right: 20px; padding-right: 20px; overflow: auto;
  }
  &-name {font-size: 2rem; line-height: 130%;}
  &-parent {font-weight: bold; margin-bottom: 0.8rem;}
  &-parent .tag { margin-left: 8px; margin-top: 0px; line-height: 16px; height: 22px; font-weight: normal; }
  &-parent a { line-height: 22px; }
  &-category {color: $navy-light; margin-bottom: 0.8rem;
    &-separator {margin: 0 5px; font-size: 0.6rem;}
  }
  &-description {font-size: 1.1rem; line-height: 130%; margin-bottom: 0.8rem; max-height: 300px;}
  &-properties {font-size: 0.8em; color: $navy; margin: 2rem 0;}
  &-property {margin-bottom: 0.5rem;
    &-value {white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
    a {color: $blue;}
    @media (max-width: 750px) {
      &-name {display: block; width: 100%;}
      &-value {display: block; width: 100%;}
    }
  }
  &-repo {
    &-stars {color: $navy; font-size: 0.8rem; white-space: nowrap; line-height: 16px; display: inline-block;
      svg {width: 16px; height: 16px; display: inline-block; vertical-align: top; }
    }
  }
  &-twitter {position: relative;
    &:after {position: absolute; content: ""; top: 0; left: 0; bottom: 0; right: 0; z-index: 100; pointer-events: none;}
  }
}

.multiline {
  height: 22px !important;
  width: 146px !important;
  position: relative !important;
  .tag-name {
    position: absolute !important;
    top: 4px !important;
    left: 4px !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .tag-value {
    font-size: 0.5rem !important;
    white-space: normal !important;
    width: 110px !important;
    padding: 0 2px !important;
    position: relative !important;
    display: table-cell !important;
    vertical-align: middle !important;
    left: 40px !important;
    height: 22px;
  }
}

.modal {
  .single-column {
    .product-property .col:nth-child(1) {
      display: inline-block;
      width: 40%;
    }
    .product-property .col:nth-child(2) {
      display: inline-block;
      width: 60%
    }

  }
}
